<template>
  <div class="content_box theme_bg radar-box">
    <tab-menu v-model="activeKey">
      <tab-pane label="雷达回波" name="1" style="position: relative;">
        <van-row >
          <van-col span="24">
            <div id="tdMap" class="my_gdmap"></div>
            <div class="options_box" >
              <van-row>
                <van-col span="24" class="op_item">
                  <p>站点选择：</p>
                  <div>
                    <van-radio-group v-model="radar.station" @change="GetLdhbListFun()">
                      <van-radio name="Z9452">齐齐哈尔</van-radio>
                      <!-- <van-radio name="Z9452">其他</van-radio> -->
                    </van-radio-group>
                  </div>
                </van-col>
                <van-col span="24" class="op_item">
                  <p>雷达类型：</p>
                  <div>
                    <!-- <van-radio-group v-model="radar.type" @change="GetLdhbListFun()">
                    <van-radio name="R">基本反射率</van-radio>
                      <van-radio name="CR">组合反射率</van-radio>
                    <van-radio name="Z9452">Z9452</van-radio>
                    <van-radio name="Z9084">Z9084</van-radio>
                    <van-radio name="he">he</van-radio>
                    </van-radio-group> -->

                    <van-checkbox-group v-model="radar.type" @change="GetLdhbListFun()">
                    <van-checkbox name="R">基本反射率</van-checkbox>
                    <van-checkbox name="CR">组合反射率</van-checkbox>
                    <van-checkbox name="Z9452">Z9452</van-checkbox>
                    <van-checkbox name="Z9084">Z9084</van-checkbox>
                    <van-checkbox name="he">he</van-checkbox>
                    </van-checkbox-group>
                  </div>
                </van-col>
              </van-row>
            </div>
            <!-- <img class="ldhb_img" :src="require(`@/assets/newImg/${radar.type}.png`)" alt=""> -->
            <img style="    display: none;" class="ldhb_img" src="@/assets/newImg/R.png" alt="">
            <time-axis class="time-axis" ref="TimeAxis" :listData="WxytList" @change="LdhbSelItem"></time-axis>
          </van-col>
        </van-row>
      </tab-pane>
      <tab-pane label="卫星云图" name="2">
        <van-row class="satellite_box">
          <van-col span="24" class="satellite-img">
            <img :src="Wxytimg" alt="">
            <!-- <img src="@/assets/newImg/R.png" alt=""> -->
          </van-col>
          <van-col span="24" class="time_axis">
            <r-progress :listData="WxytList" @change="WxytSelItem" ref="rProgress2"></r-progress>
          </van-col>
          <van-col span="24" class="Description">
            <p class="title">卫星云图说明</p>
            <p class="text">
              气象卫星测量地表和云面发射的红外辐射，将这种辐射以图象表示就是红外云图。
            </p>
            <p class="text">
              在红外云图上物体的色调决定其自身的温度，物体温度越
              高，发射的辐射越大，色调越暗，由于大气的温度随高度是递
              减的，故云顶高而厚的云，其温度低呈白的色调，在这种云层
              覆盖和即将覆盖的地方，可能将出现降雨甚至雷雨大风、冰雹
              等强对流天气。
            </p>
          </van-col>
        </van-row>
      </tab-pane>
    </tab-menu>
  </div>
</template>

<script>
import tabMenu from '@/components/myTabs/tabMenu.vue'
import tabPane from '@/components/myTabs/tabPane.vue'
import rProgress from './children/rProgress.vue'
import TimeAxis from './children/TimeAxis.vue'
import { ImagePreview } from 'vant';
import { GetRadarList, GetWxytList } from "./api"
export default {
  components: {
    tabMenu,
    tabPane,
    rProgress,
    TimeAxis
  },
  name: "radarChart",
  data() {
    return {
      layer:[],
      district:{
        he:null,
        Z9452:null,
        Z9084:null,
      },
      activeKey: "1",
      Ldhbimg: '',
      LdhbList:[],
      Wxytimg: '',
      WxytList:[],
      itemDateX:'',
      radar:{
        station:'Z9452',
        // type:'R'
        type:[]
      },
      
    }
  },
  created() {
    this.GetLdhbListFun();
    this.GetWxytListFun();

  },
  mounted() {
    setTimeout(() => {
      this.mapCreat();
    }, 300);
  },
  methods: {
    // 初始化地图
    mapCreat(){
        tdMap.map = new AMap.Map('tdMap', {
          mapStyle: "amap://styles/2652f9820f5b01731f3a45c2cedb2da4", //设置地图的显示样式
          zoom: 6,
          center: [124.45861816, 47.63122559],
          zoomEnable: true,
          scrollWheel: true,
          dragEnable: true
        });
        // 来源于index.html中引入的 ./public/js/map/gdMap.js
        tdMap.addAreaLine(230200,3);
    },

    // 放大图片
    showLgImg(url){
      ImagePreview({
        images:[url],
        closeable: true
      })
    },

    //  获取雷达数据
    async GetLdhbListFun(){
      this.LdhbSelItem(this.radar.type)
      return
      const res = await GetRadarList(this.radar);
      this.LdhbList = res.data;
      setTimeout(()=>{this.LdhbSelItem(this.itemDateX)},1000)
      this.$refs.TimeAxis.toothListFun(res.data);
    },

    // 雷达回波选中
    LdhbSelItem(list){
        
        
      console.log(list)
      console.log(this.layer)
      this.layer.forEach(element => {
        tdMap.map.remove(element);
      });
      for (const key in this.district) {
        if(this.district[key]){
          this.cleanMapLayerr(this.district[key]) 
          this.district[key] = null
        } 
      }
      
    var aaa = (item)=>{
      var obj={
        he:{
          minlng:120.54735227012624,
          maxlng:128.78001729760962,
          minlat:45.064142461335408,
          maxlat:51.16960698226346
        },
        Z9084:{
          minlng:121.80608804907007,maxlng:128.78001729760962,minlat:46.565584221379957,maxlat:51.16960698226346
        },
        Z9452:{minlng:120.54735227012624,maxlng:127.32154421424876,minlat:45.064142461335408,maxlat:49.669372279626039},
        hepath: [
          new AMap.LngLat(120.54735227012624,51.16960698226346),
          new AMap.LngLat(128.78001729760962,51.16960698226346),
          
          new AMap.LngLat(128.78001729760962,45.064142461335408),
          new AMap.LngLat(120.54735227012624,45.064142461335408),
        ],
        Z9452path: [
          new AMap.LngLat(120.54735227012624,49.669372279626039),
          new AMap.LngLat(127.32154421424876,49.669372279626039),
          new AMap.LngLat(127.32154421424876,45.064142461335408),
          new AMap.LngLat(120.54735227012624,45.064142461335408),
      ],
      Z9084path: [
        new AMap.LngLat(121.80608804907007,46.565584221379957),
          new AMap.LngLat(128.78001729760962,46.565584221379957),
          new AMap.LngLat(128.78001729760962,51.16960698226346),
          new AMap.LngLat(121.80608804907007,51.16960698226346),
          
      ]
      }
      var polygon = new AMap.Polygon({
          path: obj[item+'path'],  
          fillColor: 'rgba(0,0,0,0)', // 多边形填充颜色
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: 'red', // 线条颜色
      });
       tdMap.map.add(polygon);
       this.layer[0] = polygon;
      var obj1 = {
        Z9452:'Z9452_BCR_202208051406.png',
        Z9084:'Z9084_BCR_202208051408.png',
        he:'BCR_202208051408222.png'
      }
      this.district[item] = tdMap.imageLayerr(obj[item], './雷达/'+obj1[item]);
      }
      // debugger
      list.forEach(it=>{
        // if(this.district[it]){
        //    this.cleanMapLayerr(this.district[it])
        //    this.district[it] = null
        //    return
        // }
        
        aaa(it)
      })

        return
        this.itemDateX = item;
        console.log(item);
        // radarUrl 存放路径index.html全局变量
        const { FileUrl } = item;
        const area = {
          Z9452:{ maxlat: 49.669372279626039, maxlng: 127.32154421424876, minlat: 45.064142461335408, minlng: 120.54735227012624 },
          Z9084:{ maxlat: 51.16960698226346, maxlng: 128.78001729760962, minlat: 46.565584221379957, minlng: 121.80608804907007 }
        }
        var layer = tdMap.imageLayerr(area[this.radar.station], radarUrl + FileUrl);
        setTimeout(()=>{
          if(district) this.cleanMapLayerr(district);
          district = layer;
        },100)
    },
    //  获取卫星云图
    async GetWxytListFun(){
      const res = await GetWxytList();
      this.WxytList = res.data;
      this.$refs.rProgress2.toothListFun(res.data);
    },
    // 卫星云图选中
    WxytSelItem(item){
      this.Wxytimg = item.ImgUrl
      console.log(item)
    },
    /**清除覆盖物 */
    cleanMapLayerr(layer) {
      tdMap.map.remove(layer);//清除所有覆盖物
    }
  },

  filters: {

  }
}
</script>


<style lang="scss" scoped>
.my_gdmap{
  width: 100%;
  height: calc(100vh - 35px);
}
.ldhb_img{
  width: 50px;
  background-color: rgba($color: #fff, $alpha: 0.6);
  position: absolute;
  right: 2%;
  bottom: 30%;
}
.time-axis{
  width: 100%;
  position: absolute;
  bottom: 0%;
  // background-color: #1B315C;
  background-color: #3C9A8D;
}
.options_box{
  position: absolute;
  top: 0%;
  width: 100%;
  box-sizing: border-box;
  height: 120px;
  // background-color: #1B315C;
  background-color: #3C9A8D;
  padding: 10px;
  .op_item{
    // display: flex;
    color: #fff;
    margin-bottom: 10px;
    >p{
      margin: 0;
      // color: #4E97EC;
      color: #fff;
      white-space:nowrap;
    }
    >p::before{
      content: ' ';
      display: inline-block;
      width: 3px;
      height: 15px;
      // background-color: #4E97EC;
      margin-right: 10px;
      transform: translateY(20%);
    }
  }
  ::v-deep .van-radio-group{
    display: flex;
    flex-wrap: wrap;
    .van-radio{
      margin: 5px;
    }
  }
  ::v-deep .van-radio__label{
    color: #fff;
    font-size: 12px;
  }
}
.radar-box {
  padding-top: 10px;
  // background-image: none;
  // background-color: #1B315C;
}

.satellite_box {
  padding: 5px;
  box-sizing: border-box;
 

  .satellite-img {
    height: 250px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .time_axis {
    // height: 150px;
  }

  .Description {
    height: 250px;
    // background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 2px;
    padding: 0 10px;

    .text {
      font-size: 13px;
      text-indent: 24px;
      line-height: 22px;
    }

    .title {
      position: relative;
      text-align: center;
      font-size: 16px;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
      padding-bottom: 5px;
    }

    .title::after {
      content: ' ';
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translateX(-50%);
      width: 120px;
      height: 1px;
      border-bottom: 3px solid #308BCA;
    }
  }
}
</style>